import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { getSession } from 'next-auth/react';
import { signOut } from '~/utils/signOut';
import { API_BASE_URL, MONITOR_API_BASE_URL } from './apiBaseUrl';
import { getCurrentLocaleReqHeader } from './i18nGlobal';

const fitxAxiosServices = axios.create({
  baseURL: API_BASE_URL
});

export const fitxAxiosMonitor = axios.create({
  baseURL: MONITOR_API_BASE_URL
});

const requuestInterceptor = async (config: InternalAxiosRequestConfig) => {
  const session = await getSession();

  if (session?.token.accessToken) {
    const token = new URLSearchParams(session?.token.accessToken).get('fitx');
    config.headers['Authorization'] = `Bearer ${token}`;
  }

  if (typeof window !== 'undefined') {
    config.headers['Accept-Language'] = getCurrentLocaleReqHeader();
  }

  return config;
};

fitxAxiosServices.interceptors.request.use(requuestInterceptor, (error) => {
  return Promise.reject(error);
});

fitxAxiosMonitor.interceptors.request.use(requuestInterceptor, (error) => {
  return Promise.reject(error);
});

const responseInterceptor = (response: AxiosResponse) => {
  if (response.data.isSuccess === false) {
    return Promise.reject(new AxiosError('Request failed due to isSuccess is false', '200', response.config, response.request, response));
  }

  return Promise.resolve(response);
};

fitxAxiosServices.interceptors.response.use(responseInterceptor, async (error) => {
  if (error.response?.status === 401 && !window.location.href.includes('/login')) {
    await signOut();
  }

  return Promise.reject(error);
});

fitxAxiosMonitor.interceptors.response.use(responseInterceptor);

export default fitxAxiosServices;
